export default [
  {
    name: 'Info',
    to: 'MyProfileDashboard'
  },
  {
    name: 'Accounts',
    to: 'MyAccounts'
  },
  {
    name: 'Access',
    to: 'MyAccess'
  },
  {
    name: 'Password',
    to: 'ChangePassword'
  }
]
