<template>
  <div>
    <error-box
      v-if="error"
      :loading="loading"
      @reload="load()" />

    <div
      v-else-if="loading"
      v-loading="loading"
      class="page-loader" />

    <template v-else>
      <panel title="Settings">
        <data-list :list="settingsDataList" />
      </panel>
    </template>
  </div>
</template>

<script>
import navigation from './navigation'

export default {
  data () {
    return {
      error: false,
      loading: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration
    },

    registrationData () {
      return this.registration.data
    },

    fees () {
      let debit = false
      let debitCard

      const data = [...this.registration.fees]

      this.registration.fees.forEach(item => {
        if (item.name === 'Volume Discount') item.name = 'Volume Fee'
        if (item.name === 'Debit Card') debit = true
      })

      if (!debit) {
        const debitData = this.$store.state.fee_schedule.data || []
        debitData.forEach(item => {
          if (item.name === 'Debit Card') debitCard = item
        })
        if (debitCard) data.push(debitCard)
      }

      return data
    },

    settingsDataList () {
      return [
        {
          label: 'Send Confirmation Emails',
          text: this.$yesNo(this.registration.profileSettings.welcome_email)
        },
        {
          label: 'Profile Language',
          text: this.registration.profileSettings.language
        }
      ]
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'My Settings'
    })

    this.$store.commit('page/setTabs', navigation)

    this.load()
  },

  methods: {
    async load () {
      this.error = false
      this.loading = true
      try {
        await this.$store.dispatch('fee_schedule/load')
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
